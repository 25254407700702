import TrustVisionHttpClient from '@httpClients/TrustVisionHttpClient';
import TVAuthorizer from '@authorizers/TVAuthorizer';

export function getDomain(env: string): string {
  switch (env) {
    case 'testing':
      return 'https://tv-testing.trustingsocial.com/api';

    case 'staging':
      return 'https://tv-staging.trustingsocial.com/api';

    case 'production':
      return 'https://vn-vision.trustingsocial.com/api';
    default:
      return env;
  }
}

export default function createHttpClient(
  accessKey: string,
  keySecret: string,
  env: string = 'production',
  { headers }: any = {},
) {
  const authorizer = new TVAuthorizer(accessKey, keySecret);
  const domain = getDomain(env);
  return new TrustVisionHttpClient({ authorizer, domain, headers });
}
