import { Headers } from 'generalInterfaces';
import { HttpClient } from '@httpClients/interfaces';
import { CompareFacesResponse, CompareFacesParams } from './interfaces';

export default class CompareFacesService {
  constructor(private httpClient: HttpClient) {}

  public request(params: CompareFacesParams, headers?: Headers): Promise<CompareFacesResponse> {
    return this.httpClient.post('/v1/compare_faces_sync', params, headers);
  }
}
