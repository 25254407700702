import { Headers } from 'generalInterfaces';
import { FormDataHttpClient } from '@httpClients/interfaces';
import { RequestVerifyPortraitParams, RequestVerifyPortraitResponse } from './interfaces';

export default class RequestVerifyPortraitService {
  constructor(private httpClient: FormDataHttpClient) {}

  public request(
    params: RequestVerifyPortraitParams,
    headers?: Headers
  ): Promise<RequestVerifyPortraitResponse> {
    return this.httpClient.post('/v1/verify_portrait_sanity_sync', params, headers);
  }
}
