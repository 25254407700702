import { Authorizer } from './interfaces';
import Base64 from 'crypto-js/enc-base64';
import HmacSHA256 from 'crypto-js/hmac-sha256';
import rfc3339 from '../utils/rfc3339';

export default class TVAuthorizer implements Authorizer {
  constructor(private accessKey: string, private keySecret: string) {}

  public getHeaders(options: any): any {
    const timestime = rfc3339(new Date());
    return {
      Authorization: this.generateAuthorization(options.requestPath, options.method, timestime),
      'X-TV-Timestamp': timestime
    };
  }

  private generateAuthorization(path: string, verb: string, timestime: string): string {
    const stringToSign = this.computeStringToSign(path, verb, timestime);
    const signature = Base64.stringify(this.computeSignature(stringToSign));
    return this.formatAuthToken('TV', signature);
  }

  private formatAuthToken(type: string, signature: string) {
    return `${type} ${this.accessKey}:${signature}`;
  }

  private computeStringToSign(path: string, verb: string, timestime: string) {
    return `${verb}\n${path}\n${timestime}`;
  }

  private computeSignature(message: string) {
    const hash = HmacSHA256(message, this.keySecret);
    return hash;
  }
}
