import { Headers } from 'generalInterfaces';
import { HttpClient } from '@httpClients/interfaces';
import { ReadIDCardParams, ReadIDCardResponse } from '@services/ReadIDCard/interfaces';

export default class ReadIDCardService {
  constructor(private httpClient: HttpClient) {}

  public request(params: ReadIDCardParams, headers?: Headers): Promise<ReadIDCardResponse> {
    return this.httpClient.post('/v1/read_id_card_info_sync', params, headers);
  }
}
