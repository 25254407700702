import forge from 'node-forge';

function pkcs5Unpadding(paddedText: string): string {
  const padLen = paddedText.charCodeAt(paddedText.length - 1);

  return paddedText.slice(0, -padLen);
}

export function decrypt(
  encrypted: string,
  key: string,
  iv = forge.util.createBuffer(
    Uint8Array.from([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]).buffer
  )
): string {
  const decipher = forge.cipher.createDecipher('AES-CBC', key);
  decipher.start({ iv });
  decipher.update(forge.util.createBuffer(forge.util.hexToBytes(encrypted)));

  return pkcs5Unpadding(decipher.output.data);
}
