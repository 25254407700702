import { Headers } from 'generalInterfaces';
import {
  DetectIDTamperingParams as Params,
  DetectIDTamperingResponse as Response
} from './interfaces';
import { HttpClient } from 'httpClients/interfaces';
import { AuthHeaders } from 'generalInterfaces';

export default class DetectIDTamperingService {
  constructor(private httpClient: HttpClient) {}

  public request(params: Params, headers?: Headers): Promise<Response> {
    return this.httpClient.post('/v1/detect_id_card_tampering_sync', params, headers);
  }

  public requestWithAuthHeaders(params: Params, authHeaders: AuthHeaders): Promise<Response> {
    return this.httpClient.post('/v1/detect_id_card_tampering_sync', params, authHeaders);
  }
}

