import createHttpClient from '@utils/createHttpClient';
import TrustVisionHttpClient from '@httpClients/TrustVisionHttpClient';
import { enableTransaction } from '@utils/transaction';
import { enableEncryption } from '@utils/encryption';
import { TransactionManager } from 'transactionManagers/interfaces';
import TVTransactionManager from 'transactionManagers/TVTransactionManager';
import { EncryptionService } from 'encryptions/interfaces';
import TVEncryptionService from 'encryptions/TVEncryptionService';
import UploadImageService from '@services/UploadImage';
import UploadFileService from '@services/UploadFile';
import ConvertPDFService from '@services/ConvertPDF';
import EkycPDFService from '@services/EkycPDF';
import ReadIDCardService from '@services/ReadIDCard';
import CompareFacesService from '@services/CompareFaces';
import ClientSettingsService from '@services/ClientSettings';
import VerifyFaceLivenessService from '@services/VerifyFaceLiveness';
import RequestVerifyIDCardService from '@services/RequestVerifyIDCard';
import RequestVerifyPortraitService from '@services/RequestVerifyPortrait';
import SearchFaceService from '@services/SearchFaces';
import DetectIDTamperingService from '@services/DetectIDTampering';
import { ClientSettingsResponse } from '@services/ClientSettings/interfaces';
import { CompareFacesParams, CompareFacesResponse } from '@services/CompareFaces/interfaces';
import { ConvertPDFParams, ConvertPDFResponse } from '@services/ConvertPDF/interfaces';
import { ReadIDCardParams, ReadIDCardResponse } from '@services/ReadIDCard/interfaces';
import {
  RequestVerifyIDCardParams,
  RequestVerifyIDCardResponse
} from '@services/RequestVerifyIDCard/interfaces';
import {
  RequestVerifyPortraitParams,
  RequestVerifyPortraitResponse
} from '@services/RequestVerifyPortrait/interfaces';
import {
  UploadImageParams,
  UploadImageResponse,
  UpdateMetadataParams,
  UpdateMetadataResponse
} from '@services/UploadImage/interfaces';
import {
  UploadFileParams,
  UploadFileResponse
} from '@services/UploadFile/interfaces';
import {
  VerifyFaceLivenessSyncParams,
  VerifyFaceLivenessResponse
} from '@services/VerifyFaceLiveness/interfaces';
import {
  DeleteFacesRequestParams,
  SearchFacesRequestParams,
  SearchFacesResponse,
  DeleteFacesResponse,
  IndexFacesResponse
} from '@services/SearchFaces/interfaces';
import {
  DetectIDTamperingParams,
  DetectIDTamperingResponse
} from '@services/DetectIDTampering/interfaces';
import { SDKOptions, Headers } from 'generalInterfaces';
import { EkycPDFParams, EkycPDFResponse } from '@services/EkycPDF/interfaces';

export default class TrustVisionAPI {
  private transactionEnabled?: boolean;
  private encryptionEnabled?: boolean;
  private transactionManager: TransactionManager;
  private encryptionService: EncryptionService;
  private convertPDFService: ConvertPDFService;
  private ekycPDFService: EkycPDFService;
  private readIDCardService: ReadIDCardService;
  private imagesService: UploadImageService;
  private fileService: UploadFileService;
  private compareFaceService: CompareFacesService;
  private clientSettingsService: ClientSettingsService;
  private verifyFaceLivenessService: VerifyFaceLivenessService;
  private requestVerifyIDCardService: RequestVerifyIDCardService;
  private requestVerifyPortraitSerivce: RequestVerifyPortraitService;
  private searchFaceService: SearchFaceService;
  private detectIDTamperingService: DetectIDTamperingService;
  private httpClient: TrustVisionHttpClient;

  constructor(
    accessKey: string,
    secretKey: string,
    env: string = 'production',
    options: SDKOptions = { supportTransaction: false, enableEncryption: false }
  ) {
    this.transactionEnabled = options.supportTransaction || false;
    this.encryptionEnabled = options.enableEncryption || false;

    const httpClient = createHttpClient(accessKey, secretKey, env, { headers: options.httpHeaders });

    this.transactionManager = new TVTransactionManager(httpClient);
    this.encryptionService = new TVEncryptionService(secretKey);
    this.compareFaceService = new CompareFacesService(httpClient);
    this.clientSettingsService = new ClientSettingsService(httpClient);
    this.convertPDFService = new ConvertPDFService(httpClient);
    this.ekycPDFService = new EkycPDFService(httpClient);
    this.readIDCardService = new ReadIDCardService(httpClient);
    this.requestVerifyIDCardService = new RequestVerifyIDCardService(httpClient);
    this.requestVerifyPortraitSerivce = new RequestVerifyPortraitService(httpClient);
    this.imagesService = new UploadImageService(httpClient);
    this.fileService = new UploadFileService(httpClient);
    this.verifyFaceLivenessService = new VerifyFaceLivenessService(httpClient);
    this.searchFaceService = new SearchFaceService(httpClient);
    this.detectIDTamperingService = new DetectIDTamperingService(httpClient);
    this.httpClient = httpClient;
  }

  public enableTransaction() {
    this.transactionEnabled = true;
  }

  public disableTransaction() {
    this.transactionEnabled = false;
  }

  public enableEncryption() {
    this.encryptionEnabled = true;
  }

  public disableEncryption() {
    this.encryptionEnabled = false;
  }

  @enableTransaction({
    headerIndex: 1
  })
  @enableEncryption()
  public compareFaces(
    params: CompareFacesParams,
    headers?: Headers
  ): Promise<CompareFacesResponse> {
    return this.compareFaceService.request(params, headers);
  }

  public clientSettings(): Promise<ClientSettingsResponse> {
    return this.clientSettingsService.request();
  }

  public uploadImage(params: UploadImageParams): Promise<UploadImageResponse> {
    return this.imagesService.request(params);
  }

  public uploadFile(params: UploadFileParams): Promise<UploadFileResponse> {
    return this.fileService.uploadFile(params);
  }

  @enableTransaction({
    headerIndex: 1
  })
  @enableEncryption()
  public convertPDF(params: ConvertPDFParams, headers?: Headers): Promise<ConvertPDFResponse> {
    return this.convertPDFService.request(params, headers);
  }

  public ekycPDF(params: EkycPDFParams, headers?: Headers): Promise<EkycPDFResponse> {
    return this.ekycPDFService.request(params, headers);
  }

  @enableTransaction({
    headerIndex: 1
  })
  @enableEncryption()
  public readIDCard(params: ReadIDCardParams, headers?: Headers): Promise<ReadIDCardResponse> {
    return this.readIDCardService.request(params, headers);
  }

  @enableTransaction({
    headerIndex: 1
  })
  @enableEncryption()
  public requestVerifyIDCard(
    params: RequestVerifyIDCardParams,
    headers?: Headers
  ): Promise<RequestVerifyIDCardResponse> {
    return this.requestVerifyIDCardService.request(params, headers);
  }

  @enableTransaction({
    headerIndex: 1
  })
  @enableEncryption()
  public requestVerifyPortrait(
    params: RequestVerifyPortraitParams,
    headers?: Headers
  ): Promise<RequestVerifyPortraitResponse> {
    return this.requestVerifyPortraitSerivce.request(params, headers);
  }

  @enableTransaction({
    headerIndex: 1
  })
  @enableEncryption()
  public verifyFaceLiveness(
    params: VerifyFaceLivenessSyncParams,
    headers?: Headers
  ): Promise<VerifyFaceLivenessResponse> {
    return this.verifyFaceLivenessService.request(params, headers);
  }

  @enableTransaction({
    headerIndex: 1
  })
  @enableEncryption()
  public searchFaces(
    params: SearchFacesRequestParams,
    headers?: Headers
  ): Promise<SearchFacesResponse> {
    return this.searchFaceService.request(params, headers);
  }

  @enableTransaction({
    headerIndex: 1
  })
  @enableEncryption()
  public deleteFaces(
    params: DeleteFacesRequestParams,
    headers?: Headers
  ): Promise<DeleteFacesResponse> {
    return this.searchFaceService.deleteFaces(params, headers);
  }

  @enableTransaction({
    headerIndex: 1
  })
  @enableEncryption()
  public indexFaces(
    params: SearchFacesRequestParams,
    headers?: Headers
  ): Promise<IndexFacesResponse> {
    return this.searchFaceService.indexFaces(params, headers);
  }

  public downloadFaceImage(faceId: string): Promise<string> {
    return this.searchFaceService.downloadFaceImage(faceId);
  }

  public downloadImage(imageId: string): Promise<string> {
    return this.imagesService.download(imageId);
  }

  public updateMetadata(
    imageId: string,
    params: UpdateMetadataParams
  ): Promise<UpdateMetadataResponse> {
    return this.imagesService.updateMetadata(imageId, params);
  }

  @enableTransaction({
    headerIndex: 1
  })
  @enableEncryption()
  public detectIDTampering(
    params: DetectIDTamperingParams,
    headers?: Headers
  ): Promise<DetectIDTamperingResponse> {
    return this.detectIDTamperingService.request(params, headers);
  }
}
