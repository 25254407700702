import { HttpClient } from '@httpClients/interfaces';
import { ClientSettingsResponse } from './interfaces';

export default class ClientSettingsService {
  constructor(private httpClient: HttpClient) {}
  
  public getClientSettings(): Promise<ClientSettingsResponse> {
    return this.httpClient.get('/v1/client_settings');
  }

  public request(): Promise<ClientSettingsResponse> {
    return this.httpClient.get('/v1/client_settings');
  }
}
