import { Headers } from 'generalInterfaces';
import { SearchFacesRequestParams, DeleteFacesRequestParams, IndexFacesResponse, SearchFacesResponse, DeleteFacesResponse } from './interfaces';
import { FileDownloaderHttpClient } from '@httpClients/interfaces';

export default class SearchFaces {
  constructor(private httpClient: FileDownloaderHttpClient) {}

  public request(
    params: SearchFacesRequestParams,
    headers?: Headers
  ): Promise<SearchFacesResponse> {
    return this.httpClient.post('/v1/search_faces_sync', params, headers);
  }

  public indexFaces(
    params: SearchFacesRequestParams,
    headers?: Headers
  ): Promise<IndexFacesResponse> {
    return this.httpClient.post('/v1/index_faces_sync', params, headers);
  }

  public deleteFaces(
    params: DeleteFacesRequestParams,
    headers?: Headers
  ): Promise<DeleteFacesResponse> {
    return this.httpClient.post('/v1/delete_faces', params, headers);
  }

  public async downloadFaceImage(faceId: string): Promise<string> {
    const blob = await this.httpClient.downloadFile(`/v1/face_images/${faceId}`);
    const url = URL.createObjectURL(blob);
    return url;
  }
}

