import TrustVisionHttpClient from '@httpClients/TrustVisionHttpClient';
import TVAuthorizer from '@authorizers/TVAuthorizer';

export type Env = 'staging' | 'testing' | 'production';

export function getDomain(env?: Env): string {
  switch (env) {
    case 'testing':
      return 'https://tv-testing.trustingsocial.com/api';

    case 'staging':
      return 'https://tv-staging.trustingsocial.com/api';

    case 'production':
    default:
      return 'https://vn-vision.trustingsocial.com/api';
  }
}

export default function createHttpClient(
  accessKey: string,
  keySecret: string,
  env: Env = 'production'
) {
  const authorizer = new TVAuthorizer(accessKey, keySecret);
  const domain = getDomain(env);
  return new TrustVisionHttpClient({ authorizer, domain });
}
