export type FormDataParams = {
  [key: string]: Blob | string;
};

export default function(params: FormDataParams): FormData {
  return Object.entries(params).reduce((formData, [key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v, k) => formData.append(`${key}[${k}]`, value));
    } else if (
      value instanceof Object &&
      !(value instanceof File) &&
      !(value instanceof Date) &&
      !(value instanceof Blob)
    ) {
      Object.entries(value).forEach((v, k) => formData.append(`${key}[${k}]`, value[k]));
    } else {
      formData.append(key, value);
    }

    return formData;
  }, new FormData());
}
