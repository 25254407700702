import { Headers } from 'generalInterfaces';
import { ConvertPDFParams, ConvertPDFResponse } from './interfaces';
import { FormDataHttpClient } from '@httpClients/interfaces';

export default class ConvertPDFService {
  constructor(private httpClient: FormDataHttpClient) {}

  public request(params: ConvertPDFParams, headers?: Headers): Promise<ConvertPDFResponse> {
    return this.httpClient.postFormData('/v1/convert_pdf_sync', params, headers);
  }
}
