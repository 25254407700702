import { FileDownloaderHttpClient } from '@httpClients/interfaces';
import { UploadFileParams, UploadFileResponse } from './interfaces';
import { AuthHeaders, Headers, Response } from 'generalInterfaces';

// current use to upload video/audio
export default class UploadFileService {
  constructor(private httpClient: FileDownloaderHttpClient) {}

  public request(params: UploadFileParams, headers?: Headers): Promise<Response> {
    return this.httpClient.post('/v1/files', params, headers);
  }

  public uploadFile(params: UploadFileParams): Promise<UploadFileResponse> {
    return this.httpClient.postFormData('/v1/files', params);
  }

  public requestWithHeaders(params: UploadFileParams, headers: AuthHeaders): Promise<UploadFileResponse> {
    return this.httpClient.postFormData('/v1/files', params, headers);
  }
}
