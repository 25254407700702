import { Headers } from 'generalInterfaces';
import { RequestVerifyIDCardParams, RequestVerifyIDCardResponse } from './interfaces';
import { FormDataHttpClient } from '@httpClients/interfaces';

export default class RequestVerifyIDCardService {
  constructor(private httpClient: FormDataHttpClient) {}

  public request(
    params: RequestVerifyIDCardParams,
    headers?: Headers
  ): Promise<RequestVerifyIDCardResponse> {
    return this.httpClient.post('/v1/verify_id_card_sanity_sync', params, headers);
  }
}
