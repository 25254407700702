import { FileDownloaderHttpClient } from '@httpClients/interfaces';
import { UploadImageParams, UploadImageResponse, UpdateMetadataParams, UpdateMetadataResponse } from './interfaces';
import { AuthHeaders } from 'generalInterfaces';

export default class UploadImageService {
  constructor(private httpClient: FileDownloaderHttpClient) {}

  /* backward compatible */
  public uploadImage(params: UploadImageParams): Promise<UploadImageResponse> {
    return this.httpClient.postFormData('/v1/images', params);
  }

  public request(params: UploadImageParams): Promise<UploadImageResponse> {
    const isPDFType = params.file.type === 'application/pdf';
    return this.httpClient.postFormData(isPDFType ? '/v1/files' : '/v1/images', params);
  }

  public requestWithHeaders(params: UploadImageParams, headers: AuthHeaders): Promise<UploadImageResponse> {
    return this.httpClient.postFormData('/v1/images', params, headers);
  }

  public async download(imageId: string ): Promise<string> {
    const file = await this.httpClient.downloadFile(`/v1/images/${imageId}`);
    const url = await URL.createObjectURL(file);
    return url;
  }

  public updateMetadata(imageId: string, params: UpdateMetadataParams): Promise<UpdateMetadataResponse> {
    return this.httpClient.patch(`/v1/images/${imageId}`, params);
  }
}
