import { Headers } from 'generalInterfaces';
import { EkycPDFParams, EkycPDFResponse } from './interfaces';
import { FormDataHttpClient } from '@httpClients/interfaces';

export default class EkycPDFService {
  constructor(private httpClient: FormDataHttpClient) {}

  public request(params: EkycPDFParams, headers?: Headers): Promise<EkycPDFResponse> {
    return this.httpClient.post('/v1/ekyc_pdf', params, headers);
  }
}
