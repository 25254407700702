import urljoin from 'url-join';
import { stringify } from 'querystring';
import { Authorizer } from '@authorizers/interfaces';
import { Response } from 'generalInterfaces';
import toFormData, { FormDataParams } from '@utils/toFormData';
import { FileDownloaderHttpClient } from './interfaces';

type ConstructorOptions = {
  authorizer?: Authorizer;
  domain: string;
  onError?: Function;
  headers?: any;
};

class TokenizedClient implements FileDownloaderHttpClient {
  private authorizer?: Authorizer;
  private domain: string;
  private onError?: Function;
  private configHeaders?: any;

  constructor(options: ConstructorOptions) {
    this.authorizer = options.authorizer;
    this.domain = options.domain;
    this.onError = options.onError;
    this.configHeaders = options.headers;
  }

  public get<T extends Response>(
    requestPath: string,
    params?: URLSearchParams,
    headers?: any
  ): Promise<T> {
    const url = new URL(this.resolveUrl(requestPath));
    if (params) {
      url.search = stringify(params);
    }
    return this.request(url.toString(), {
      requestPath,
      method: 'GET',
      headers
    });
  }

  public post<T extends Response>(requestPath: string, body?: any, headers?: any): Promise<T> {
    const url = this.resolveUrl(requestPath);
    return this.request(url, {
      requestPath,
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        ...headers
      }
    });
  }

  public postFormData<T extends Response>(
    requestPath: string,
    body: FormDataParams | FormData,
    headers: any
  ): Promise<T> {
    const formData = body instanceof FormData ? body : toFormData(body);
    const url = this.resolveUrl(requestPath);
    return this.request(url, {
      requestPath,
      method: 'POST',
      body: formData,
      headers
    });
  }

  public patch<T extends Response>(requestPath: string, body?: any, headers?: any): Promise<T> {
    const url = this.resolveUrl(requestPath);
    return this.request(url, {
      requestPath,
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        ...headers
      }
    });
  }

  public delete<T extends Response>(requestPath: string, body?: any, headers?: any): Promise<T> {
    const url = this.resolveUrl(requestPath);
    return this.request(url, {
      requestPath,
      method: 'DELETE',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        ...headers
      }
    });
  }

  public downloadFile<T extends Response>(requestPath: string): Promise<T> {
    const url = this.resolveUrl(requestPath);

    return this.request(
      url,
      {
        requestPath,
        method: 'GET'
      },
      'blob'
    );
  }

  public async request<T extends Response>(url: string, options: any, parser = 'json'): Promise<T> {
    let additionalHeaders: any = {};
    let configHeaders: any = {};

    if (this.authorizer) {
      additionalHeaders = this.authorizer.getHeaders(options);
    }
    if (this.configHeaders) {
      configHeaders = this.configHeaders;
    }
    options.headers = {
      ...options.headers,
      ...additionalHeaders,
      ...configHeaders,
    };

    const response = await fetch(url, options);

    const data = await (parser === 'blob' ? response.blob() : response.json());

    if (data.errors && this.onError) {
      this.onError(data.errors);
      throw data;
    }

    return data;
  }

  private resolveUrl(requestPaths: string): string {
    return urljoin(this.domain, requestPaths);
  }
}

export default TokenizedClient;
