import { HttpClient } from '@httpClients/interfaces';
import { TransactionManager } from './interfaces';

const INTERVAL = 100; // 100ms
const TIMEOUT = 5 * 60 * 10; // 5mins

export default class TVTransactionManager implements TransactionManager {
  private transactionID: string;
  private totalTransactions: number;
  private currentTransaction: number;

  constructor(public httpClient: HttpClient) {
    this.transactionID = '';
    this.totalTransactions = 0;
    this.currentTransaction = 0;
  }

  get currentTransactionID(): string {
    return this.transactionID;
  }

  async begin(): Promise<string> {
    const ticketID = this.totalTransactions++;
    await new Promise((resolve, reject) => {
      const startTime = window.performance.now();
      const intervalID = setInterval(() => {
        if (ticketID === this.currentTransaction) {
          clearInterval(intervalID);
          resolve(true);
        }
        if (window.performance.now() - startTime >= TIMEOUT) {
          clearInterval(intervalID);
          reject(new Error('Transaction timeout'));
        }
      }, INTERVAL);
    });
    const { data } = await this.httpClient.post('/v1/transactions', {});
    this.transactionID = data.id;

    return data.id;
  }

  async end() {
    const response = await this.httpClient.post(`/v1/transactions/${this.transactionID}/finish`);
    this.transactionID = '';
    this.currentTransaction++;

    return response;
  }
}
