import { TransactionManager } from 'transactionManagers/interfaces';
import { EncryptionService } from 'encryptions/interfaces';

export function enableEncryption(options: { encryptedField: string } = { encryptedField: 'data' }) {
  return function(_target: any, _propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;
    const newDescriptor = { ...descriptor };

    async function newMethod(
      this: {
        encryptionEnabled: boolean;
        transactionManager: TransactionManager;
        encryptionService: EncryptionService;
      },
      ...args: any[]
    ) {
      const response = await originalMethod.apply(this, args);

      if (this.encryptionEnabled && typeof response[options.encryptedField] === 'string') {
        const decryptedMessage = this.encryptionService.decrypt(response[options.encryptedField], {
          transactionID: this.transactionManager.currentTransactionID
        });

        return JSON.parse(decryptedMessage);
      } else {
        return response;
      }
    }

    newDescriptor.value = newMethod;

    return newDescriptor;
  };
}
