import { TransactionManager } from 'transactionManagers/interfaces';

export function enableTransaction({ headerIndex }: { headerIndex: number }) {
  return function(_target: any, _propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;
    const newDescriptor = { ...descriptor };

    async function newMethod(
      this: { transactionEnabled: boolean; transactionManager: TransactionManager },
      ...args: any[]
    ) {
      if (this.transactionEnabled) {
        const transactionID = await this.transactionManager.begin();
        const newArgs = [...args];
        const headers = { ...newArgs[headerIndex] };
        headers['X-TV-Transaction'] = transactionID;
        newArgs[headerIndex] = headers;
        const response = await originalMethod.apply(this, newArgs);
        await this.transactionManager.end();

        return response;
      } else {
        return await originalMethod.apply(this, args);
      }
    }

    newDescriptor.value = newMethod;

    return newDescriptor;
  };
}
