import { Headers } from 'generalInterfaces';
import { HttpClient } from '@httpClients/interfaces';
import { VerifyFaceLivenessResponse, VerifyFaceLivenessSyncParams } from './interfaces';

export default class VerifyFaceLiveness {
  constructor(private httpClient: HttpClient) {}

  public request(
    params: VerifyFaceLivenessSyncParams,
    headers?: Headers
  ): Promise<VerifyFaceLivenessResponse> {
    return this.httpClient.post('/v1/verify_face_liveness_sync', params, headers);
  }
}
