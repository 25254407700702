import { Response } from 'generalInterfaces';
import { FileDownloaderHttpClient } from './interfaces';

interface Mocks {
  [propName: string]: any;
  resolveUrl?(path: string): string;
}

function delay(ms: number = 2000): Promise<void> {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}

class MockClient implements FileDownloaderHttpClient {
  constructor(private mocks: Mocks) {}

  get<T extends Response>(path: string, params: any): Promise<T> {
    return this.findInMocks(path, params);
  }

  post<T extends Response>(path: string, params: any): Promise<T> {
    return this.findInMocks(path);
  }

  postFormData<T extends Response>(path: string, params: any): Promise<T> {
    return this.findInMocks(path);
  }

  patch<T extends Response>(path: string, params: any): Promise<T> {
    return this.findInMocks(path);
  }

  delete<T extends Response>(path: string, params: any): Promise<T> {
    return this.findInMocks(path);
  }
  
   downloadFile<T extends Response>(requestPath: string): Promise<T> {
    return this.findInMocks(requestPath);
  }

  resolveUrl(path: string): string {
    if (this.mocks.resolveUrl) {
      return this.mocks.resolveUrl(path);
    }
    return path;
  }

  async findInMocks<T extends Response>(path: string, params?: any): Promise<T> {
    if (!this.mocks[path]) {
      return Promise.reject(new Error(`${path} is not presented in mocks`));
    }
    let mock = this.mocks[path];
    if (typeof mock === 'function') {
      return Promise.resolve(this.mocks[path](params));
    }
    return Promise.resolve(this.mocks[path]);
  }
}

export default MockClient;
