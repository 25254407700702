import { EncryptionService } from './interfaces';
import * as CryptoUtils from '../utils/crypto';

function computeFinalSecretKey(secretKey: string, transactionID: string): string {
  return `${secretKey.slice(0, 16)}${transactionID.slice(0, 16)}`;
}

export default class TVEncryptionService implements EncryptionService {
  constructor(private secretKey: string) {}

  encrypt(_message: string, _options: any): string {
    throw new Error('Method not implemented.');
  }

  decrypt(message: string, options: { transactionID: string }) {
    return CryptoUtils.decrypt(
      message,
      computeFinalSecretKey(this.secretKey, options.transactionID)
    );
  }
}
