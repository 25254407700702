import UploadImageService from './services/UploadImage';
import HttpClient from './httpClients/TrustVisionHttpClient';
import { UploadImageParams, UploadImageResponse } from './services/UploadImage/interfaces';
import { AuthHeaders } from './generalInterfaces';
import { getDomain } from './utils/createHttpClient';
import DetectIDTamperingService from '@services/DetectIDTampering';
import { DetectIDTamperingParams, DetectIDTamperingResponse } from '@services/DetectIDTampering/interfaces';

export default class SimpleTrustVisionAPI {
  private imagesService: UploadImageService;
  private detectIDTamperingService: DetectIDTamperingService;

  constructor(env: string = 'production') {
    const httpClient = new HttpClient({
      domain: getDomain(env)
    });
    this.imagesService = new UploadImageService(httpClient);
    this.detectIDTamperingService = new DetectIDTamperingService(httpClient);
  }

  public uploadImage(params: UploadImageParams, authHeaders: AuthHeaders): Promise<UploadImageResponse> {
    return this.imagesService.requestWithHeaders(params, authHeaders);
  }

  public detectTampering(params: DetectIDTamperingParams, authHeaders: AuthHeaders): Promise<DetectIDTamperingResponse> {
    return this.detectIDTamperingService.requestWithAuthHeaders(params, authHeaders);
  }
}
